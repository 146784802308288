<!-- eslint-disable vue/no-template-key -->
<template>
<div>
<b-container fluid>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <div class="card-title">
                        <h4><i class="fa fa-briefcase"></i>Bestellungen</h4>
                        <p>Liste aller Bestellungen</p>
                    </div>

                    <div class="clearfix card-tools">
                        <div class="row align-items-center" >                          
                            <div class="text-right col-md-12">

                                <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                    <ul class="navbar-nav">  

                                        <li class="nav-item mr-1">
                                            <div class="input-group input-group-sm mt-0">
                                                <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getResults" :value="search" @input="$store.commit('orders/changeSearch', $event.target.value)"/>
                                                <button type="submit" class="btn btn-default btn-sm" @click.prevent="getResults">
                                                    <i class="fas fa-search"></i>
                                                </button>
                                                <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </li>
                                        
                                        <li class="nav-item">
                                            <button type="button" class="mr-1 btn btn-sm btn-secondary" @click="showSupplierOrderModal" :disabled="selectedOrders.length <= 0" >Lieferantenbestellung</button>       
                                        </li>

                                        <li class="mr-1 nav-item dropdown d-none d-md-block">
                                            <button type="button" id="AdministrationSubMenu" href="#" :disabled="selectedOrders.length <= 0" class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-boxes"></i> Stapelverarbeitung
                                            </button>
                                            <ul class="border-0 shadow dropdown-menu" aria-labelledby="SettingsSubMenu" style="left: 0px; right:inherit;">

                                                <li v-if="$auth.check('companies.index') && useDropshipping == 'false'">
                                                    <a class="dropdown-item" href="#" @click="printPicklist">Drucken: Pickliste</a>
                                                </li>

                                                <li class="dropdown-submenu dropdown-hover">
                                                    <a id="StatusSubmenu" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-item dropdown-toggle">Statuspflege</a>
                                                    <ul aria-labelledby="StatusSubmenu" class="border-0 shadow dropdown-menu ">
                                                        <li v-for="state in orderStates" :key="state.id">
                                                            <a @click="setStatus('order', state.id)" class="dropdown-item">{{ state.description }} </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li v-if="$auth.check('companies.index') && useDropshipping == 'false'">
                                                    <a class="dropdown-item" href="#" @click="setStatusAndPrint('order', 2)">Status & Drucken</a>
                                                </li>
                                                <li v-if="$auth.check('routes.index')">
                                                    <a class="dropdown-item" href="#" @click="createRoute">Routenplanung erstellen</a>
                                                </li>
                                            </ul>
                                        </li>
            
                                        <li class="nav-item d-none d-md-block">
                                            <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'orders.create'}" v-if="$auth.check('orders.create')"><i class="fas fa-plus-circle"></i> Neue Bestellung</router-link>             
                                        </li>

                                        <li class="nav-item d-none d-md-block">
                                            <button type="button" class="mr-1 btn btn-sm btn-success" @click="getResults" ><i class="fas fa-sync"></i></button>       
                                        </li>

                                        <li class="nav-item d-none d-md-block">
                                            <button type="button" class="btn btn-sm btn-secondary mr-1" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                        </li>

                                        <li class="nav-item d-none d-md-block">
                                            <button type="button" class="btn btn-sm btn-info" @click="toggleOptions"><i class="fas fa-cog"></i></button>
                                        </li>
                                        
                                    </ul>
                
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <b-row style="border: 1px solid black;" v-if="showOptions">
                        <b-col>
                            <h5>Optionen</h5>
                            <fieldset class="form-group">
                                <div class="form-row">
                                    <legend tabindex="-1" class="col-lg-2 bv-no-focus-ring col-form-label">Spalten</legend>
                                    <div class="col">
                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchDate" v-model="showDate">
                                            <label class="custom-control-label" for="customSwitchDate">Datum</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchInfos" v-model="showInfos">
                                            <label class="custom-control-label" for="customSwitchInfos">Infos</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchOrder" v-model="showOrdernumber">
                                            <label class="custom-control-label" for="customSwitchOrder">Bestellnummer</label>
                                        </div>
                                        
                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchInvoice" v-model="showInvoiceNumber">
                                            <label class="custom-control-label" for="customSwitchInvoice">Rechnungsnummer</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchAmount" v-model="showAmount">
                                            <label class="custom-control-label" for="customSwitchAmount">Betrag</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchBillingMethod" v-model="showBillingMethod">
                                            <label class="custom-control-label" for="customSwitchBillingMethod">Zahlungsart</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchShippingMethod" v-model="showShippingMethod">
                                            <label class="custom-control-label" for="customSwitchShippingMethod">Versandart</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchCompany" v-model="showCompany">
                                            <label class="custom-control-label" for="customSwitchCompany">Firma</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchCustomer" v-model="showCustomer">
                                            <label class="custom-control-label" for="customSwitchCustomer">Kunde</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchCity" v-model="showCity">
                                            <label class="custom-control-label" for="customSwitchCity">Ort</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchVouchers" v-model="showVouchers">
                                            <label class="custom-control-label" for="customSwitchVouchers">Gutscheine</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchMail" v-model="showMail">
                                            <label class="custom-control-label" for="customSwitchMail">E-Mail</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchShippingState" v-model="showShippingState">
                                            <label class="custom-control-label" for="customSwitchShippingState">Bestellstatus</label>
                                        </div>

                                        <div class="custom-control custom-control-inline custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchBillingState" v-model="showBillingState">
                                            <label class="custom-control-label" for="customSwitchBillingState">Zahlstatus</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>
                                    <th scope="col"><input type="checkbox" v-model="selectAll" /></th>
                                    <th scope="col" v-if="showDate">
                                        <a href="#" @click.prevent="changeSort('orderdate')">Datum</a>
                                        <span v-if="this.params.sort_field == 'orderdate' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'orderdate' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showInfos" style="width: 10%;">Infos</th>
                                    <th scope="col" v-if="showOrdernumber">
                                        <a href="#" @click.prevent="changeSort('ordernumber')">Best.-Nr.</a>
                                        <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showInvoiceNumber">R.-Nr</th>
                                    <th scope="col" v-if="showAmount"><a href="#" @click.prevent="changeSort('invoice_amount')">Betrag</a>
                                        <span v-if="this.params.sort_field == 'invoice_amount' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'invoice_amount' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showBillingMethod">
                                        <a href="#" @click.prevent="changeSort('payment')">Zahlungsart</a>
                                        <span v-if="this.params.sort_field == 'payment' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'payment' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showShippingMethod">
                                        <a href="#" @click.prevent="changeSort('dispatch')">Versandart</a>
                                        <span v-if="this.params.sort_field == 'dispatch' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'dispatch' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showCompany">
                                        <a href="#" @click.prevent="changeSort('company')">Firma</a>
                                        <span v-if="this.params.sort_field == 'company' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'company' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showCustomer">
                                        <a href="#" @click.prevent="changeSort('customer')">Kunde</a>
                                        <span v-if="this.params.sort_field == 'customer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'customer' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showCity">
                                        <a href="#" @click.prevent="changeSort('shipping__city')">Ort</a>
                                        <span v-if="this.params.sort_field == 'shipping__city' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'shipping__city' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showVouchers">
                                        Gutscheine
                                    </th>
                                    <th scope="col" v-if="showMail">
                                        <a href="#" @click.prevent="changeSort('email')">E-Mail</a>
                                        <span v-if="this.params.sort_field == 'email' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'email' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showShippingState">
                                        <a href="#" @click.prevent="changeSort('order_status')">Bestellstatus</a>
                                        <span v-if="this.params.sort_field == 'order_status' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'order_status' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col" v-if="showBillingState">
                                        <a href="#" @click.prevent="changeSort('billing_status')">Zahlstatus</a>
                                        <span v-if="this.params.sort_field == 'billing_status' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'billing_status' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col">Aktionen</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(order,index) in orders.data" :key="order.id" :class="generateClasses(index)">
                                        <td><input type="checkbox" :value="order.id" v-model="selectedOrders"/></td>
                                        <td v-if="showDate">{{ order.orderdate}}</td>
                                        <td v-if="showInfos" style="width: 10%;">
                                            <span v-if="order.rsu != null" class="badge badge-pill badge-primary">RSU</span>
                                            <span v-if="order.email != null" class="badge badge-pill badge-danger">LA: {{ order.email }}</span>
                                            <span v-if="order.phone != null" class="badge badge-pill badge-danger">LA: {{ order.phone }}</span>
                                            <span v-if="order.dispatch.express == 1" class="badge badge-pill badge-danger">Express</span>
                                            <span v-if="order.prioExternal == 1" class="badge badge-pill badge-warning">Prio</span>
                                            <span v-if="order.abo == 1" class="badge badge-pill badge-primary">Abo</span>
                                            <span v-if="order.wishdate != null && order.fixWishdate == 0" class="badge badge-pill badge-warning">WLT: {{ order.wishdate | formatDate }}</span>
                                            <span v-if="order.wishdate != null && order.fixWishdate == 1" class="badge badge-pill badge-danger">FLT: {{ order.wishdate | formatDate }}</span>
                                        </td>
                                        <td v-if="showOrdernumber">
                                            <span class="badge badge-pill badge-dark">{{ order.ordernumber }}</span>
                                        </td>
                                        <td v-if="showInvoiceNumber"><span class="badge badge-pill badge-light">{{order.invoice_number}}</span></td>
                                        <td v-if="showAmount">
                                            {{ order.invoice_amount | toCurrency }}<br>
                                            <span class="text-primary" v-if="order.total_stornos != 0"><strong>{{ order.invoice_amount + order.total_stornos | toCurrency }}</strong></span>
                                        </td>
                                        <td v-if="showBillingMethod">{{ order.payment_name }}</td>
                                        <td v-if="showShippingMethod">{{ order.dispatch_name }}</td>
                                        <td v-if="showCompany">{{ order.company_name }}</td>
                                        <td v-if="showCustomer">
                                            <span v-if="order.billing_company != null"><strong>{{ order.billing_company }}</strong><br></span>
                                            <span>{{ order.billing_name}}</span>
                                        </td>
                                        <td v-if="showCity">{{ order.shipping_city}}</td>
                                        <td v-if="showVouchers">
                                            <ul style="list-style: none; padding: 0;">
                                                <li v-for="voucher in order.vouchers" :key="voucher.id">
                                                    <span class="badge badge-pill badge-light">{{ voucher.code }}</span>
                                                </li>
                                                <li v-if="order.friendCode">
                                                    <span class="badge badge-pill badge-light">{{ order.friendCode.share_code }}</span>
                                                </li>
                                            </ul>
                                        </td>
                                        <td v-if="showMail">{{ order.customer_email}}</td>
                                        <td v-if="showShippingState">
                                            <div v-if="selectedRow[index] && selectedCell === 'order_status'" class="input-group">
                                                <select class="form-control form-control-sm" v-model="orders.data[index].order_status" @change="changeStatus('order', index)" v-if="$auth.check('orders.edit')">
                                                    <option v-for="state in orderStates" :key="state.id" :value="state.id">{{ state.description }}</option> 
                                                </select>
                                                <div class="input-group-append">
                                                    <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('order', index)"><i class="fas fa-save"></i></button> -->
                                                    <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'order_status')"><i class="fas fa-times"></i></button>
                                                </div>
                                            </div>
                                            <span @dblclick="handleEditCell(index, 'order_status')" v-else class="badge" :class="setOrderBadge(order.order_state)">{{ order.order_state }}</span>
                                        </td>
                                        <td v-if="showBillingState">
                                            <div v-if="selectedRow[index] && selectedCell === 'billing_status'" class="input-group">
                                                <select class="form-control form-control-sm" v-model="orders.data[index].billing_status" @change="changeStatus('billing', index)" v-if="$auth.check('orders.edit')">
                                                    <option v-for="state in billingStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                                                </select>
                                                <div class="input-group-append">
                                                    <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('billing', index)"><i class="fas fa-save"></i></button> -->
                                                    <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'billing_status')"><i class="fas fa-times"></i></button>
                                                </div>
                                            </div>
                                            <span @dblclick="handleEditCell(index, 'billing_status')" v-else class="badge" :class="setBillingBadge(order.billing_state)">{{ order.billing_state }}</span>
                                        </td>
                                        <td>
                                            <!-- <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: props.row.product.id}}" v-if="$auth.check('products.show') && props.row.product != null"><i class="fas fa-box"></i></router-link> -->
                                            <router-link class="mr-1 btn btn-info btn-xs" :to="{name: 'orders.show', params: {id: order.id}}" v-if="$auth.check('orders.show') && !$auth.check('orders.edit')"><i class="fas fa-fw fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'customers.details', params: {id: order.customer_id}}" v-if="$auth.check('customers.show')"><i class="fas fa-fw fa-user"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'orders.details', params: {id: order.id}}" v-if="$auth.check('orders.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <!-- <b-button size="xs" @click="deleteOrder(order.id)" variant="danger" v-if="$auth.check('orders.destroy')"><i class="fas fa-fw fa-trash"></i></b-button> -->
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                            
                        </b-col>
                    </b-row>
                </div>
                <div class="clearfix align-middle card-footer">
                    <pagination v-if="notEmptyObject(orders)" class="float-left" :data="orders" @pagination-change-page="getResults" :limit="3"></pagination>
                    
                    <select class="float-right form-control-sm" v-model="params.per_page">
                        <option value="25">25 Artikel</option>
                        <option value="50">50 Artikel</option>
                        <option value="75">75 Artikel</option>
                        <option value="100">100 Artikel</option>
                        <option value="125">125 Artikel</option>
                        <option value="150">150 Artikel</option>
                    </select>
                    <p v-if="notEmptyObject(orders)" class="float-right mr-2">Anzeige Eintrag {{ orders.meta.from }} - {{ orders.meta.to }} von {{ orders.meta.total }}</p>
                </div>
            </div>
        </div>

        <b-modal id="offerModal" title="Lieferantenbestellung" size="xl" @ok="doOrder">
            <div class="accordion" id="accordionOffers">
                <div class="card" v-for="(offer, index) in offers" :key="offer.id">
                    <div class="card-header" :id="'heading' + offer.id">
                        <h2 class="mb-0">
                            <button class="text-left btn btn-link btn-block" type="button" data-toggle="collapse" :data-target="'#collapse' + offer.id" aria-expanded="true" :aria-controls="'collapse' + offer.id">
                            {{ offer.name }}
                            </button>
                        </h2>
                    </div>

                    <div :id="'collapse' + offer.id" class="collapse" :aria-labelledby="'heading' + offer.id" data-parent="#accordionOffers">
                        <div class="card-body">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <th>Bestellnummer</th>
                                    <th>Artikelname</th>
                                    <th>Menge</th>
                                </thead>

                                <tbody>
                                    <tr v-for="(item,index2) in offer.items" :key="item.article_id">
                                        <td>{{ item.ordernumber }}</td>
                                        <td>{{ generateName(index, index2) }}</td>
                                        <td>{{ item.quantity }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <template #modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="secondary" @click="cancel()">
                    Abbrechen
                </b-button>
                <b-button size="sm" variant="danger" @click="ok()" v-if="useDropshipping == 'true'">
                    Bestellung auslösen
                </b-button>
                
            </template>
        </b-modal>

        <b-modal :no-enforce-focus="true" id="mailDocumentModal" scrollable title="E-Mail an den Kunden senden" ok-variant="primary" ok-title="E-Mail senden" cancel-title="Abbrechen" size="xl" @ok="sendNotification">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="">An:</label>
                    <select class="form-control form-control-sm" multiple v-model="selected_emails">
                        <option v-for="email in emails" :key="email" :value="email">{{ email }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="">Betreff:</label>
                    <input type="text" class="form-control" v-model="subject" />
                </div>

                <div class="form-group">
                    <label for="">Anhänge:</label>
                    <select class="form-control" v-model="attachments" multiple>
                        <option v-for="document in order.documents" :key ="document.id" :value="document.id">{{ document.document.name + '-' + document.number }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <editor
                                v-bind:api-key="this.$tinyApiKey"
                                :init="{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                    'code advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | help'
                                }"
                                v-model="message"
                              
                                />
                </div>
            </div>
        </div>
        </b-modal>


    </div>
</b-container>

    <aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
            <h3>Filteroptionen</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Shop:</label>
                        <select v-model="params.company_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Firma --</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

             <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Kundengruppe:</label>
                        <select v-model="params.customerGroup" class="form-control form-control-sm">
                            <option :value="''">-- Filter Kundengruppe --</option>
                            <option v-for="group in customerGroups" :key="group.id" :value="group.id">{{ group.text }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Zahlungsart:</label>
                        <select v-model="params.payment_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Zahlungsart --</option>
                            <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Versandart:</label>
                        <!-- <select v-model="params.dispatch_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Versandart --</option>
                            <option v-for="dispatch in dispatches" :key="dispatch.id" :value="dispatch.id">
                                {{ dispatch.name }}
                            </option>
                        </select> -->
                        <select v-model="params.dispatch_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Versandart --</option>
                            <optgroup v-for="company in companies" :key="company.id" :label="company.name">
                                
                                <option v-for="dispatch in company.dispatches" :key="dispatch.id" :value="dispatch.id">{{ dispatch.name }}</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Bestellstatus:</label>
                        <select v-model="params.order_status_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Bestellstatus--</option>
                            <option v-for="state in orderStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Zahlstatus:</label>
                        <select v-model="params.billing_status_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Zahlstatus--</option>
                            <option v-for="state in billingStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>

        </div>  
    </aside>

</div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";
import {mapState} from 'vuex';
import { mapPreferences } from 'vue-preferences'

export default {

    title: '',
    name: 'OrderIndex',

    data() {
        return {
            showOptions: false,
            useDropshipping: process.env.VUE_APP_FUNKE_MODE,
            offers: {},
            orders: [],
            selectedOrders: [],
            payments: {},
            dispatches: {},
            shippings: {},
            companies: [],
            customerGroups: [],
            orderStates: {},
            billingStates: {},
            selectedRow: {},
            selectedCell: null,
            message: "",
            subject: "",
            emails: [],
            selected_emails: [],
            company_id: "",
            attachments: [],
            order: {},
            editor: Editor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
        }
    },

    computed: {
        ...mapPreferences({
            showOrdernumber: {
                defaultValue: true
            },
            showInvoiceNumber: {
                defaultValue: false
            },
            showDate: {
                defaultValue: true
            },
            showInfos: {
                defaultValue: true
            },
            showAmount: {
                defaultValue: true
            },
            showBillingMethod: {
                defaultValue: true
            },
            showShippingMethod: {
                defaultValue: true
            },
            showCompany: {
                defaultValue: true
            },
            showCustomer: {
                defaultValue: true
            },
            showMail: {
                defaultValue: true
            },
            showBillingState: {
                defaultValue: true
            },
            showShippingState: {
                defaultValue: true
            },
            showCity: {
                defaultValue: true
            },
            showVouchers: {
                defaultValue: true
            },
        }),
        
        selectAll: {
            get: function () {
                return this.orders.data ? this.selectedOrders.length == this.orders.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.orders.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedOrders = selected;
            },   
        },
        ...mapState('orders', ['params', 'search', 'page'])
    },

    watch: {
        params: {
            handler () {
                this.getResults();
                this.selectedOrders = [];
                this.generateCustomerGroups();
            },
            deep: true
        },
    },

    methods:{
        generateCustomerGroups(){
            var company = this.companies.find(item => item.id == this.params.company_id);
            if(company == undefined){
                this.customerGroups = [];
            }
            else{
                this.customerGroups = company.groups
            }
            
        },

        toggleOptions(){
            if(this.showOptions == false){
                this.showOptions = true;
            }
            else{
                this.showOptions = false;
            }
        },

        generateClasses(index){
            var order = this.orders.data[index]; 

            if(order.dispatch.express == 1)
            {
                return 'table-danger'; 
            }

            if(order.prioExternal == 1)
            {
                return 'table-warning'; 
            }

            return ''; 

        }, 

        resetSearch(){
            this.$store.commit('orders/changeSearch', '');
            this.getResults();
        },

        resetFilter(){
            this.params.payment_id = '';
            this.params.dispatch_id = '';
            this.params.company_id = '';
            this.params.order_status_id = '';
            this.params.billing_status_id = '';
            this.params.per_page = 25;
            this.params.customerGroup = '';
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        
        generateName(index, index2)
        {
            var article = this.offers[index].items[index2];
            var name = article.article.name;
            article.values.forEach(function (value) {
                name = name + ' - ' + value.option.name;
            });
            return name;
        },

        sendNotification(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$Progress.start();
            this.axios
            .post("/mail/send", {
                'emails': this.emails,
                'subject': this.subject,
                'content': this.message,
                'company_id': this.company_id,
                'documents': this.attachments,
            })
            .then(() => {
            this.$Progress.finish();
            this.attachments = [];
            this.selected_emails = [];
            this.order = {};
            this.$bvModal.hide('mailDocumentModal');
            this.$swal({
                icon: "success",
                title: "E-Mail gesendet",
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

        doOrder(bvModalEvt){
            bvModalEvt.preventDefault()
            var sent = false;
            this.$swal({
                    title: "Sollen die Lieferanten direkt per Mail benachrichtigt werden?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja`,
                    denyButtonText: `Nein`,
                    cancenButtonText: 'Nein',
                })
                .then((result) => {

                    if (result.isConfirmed) {
                        sent = true;
                    }
                    this.axios
                        .post("/supplier-orders/dropshipping", {
                            orders: this.selectedOrders,
                            sent: sent,
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Bestellungen wurden erzeugt!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$Progress.finish();
                            this.offers = [];
                            this.$bvModal.hide('offerModal');
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                            this.$Progress.fail();
                        });
                });
        },

        showSupplierOrderModal(){
            this.axios
                .post("/orders/offer", {
                    orders: this.selectedOrders,
                })
                .then((response) => {
                    this.offers = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });

                this.$bvModal.show('offerModal');

            
        },

        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

        changeStatus(mode, order)
        {
            var orders = [this.orders.data[order].id];
            var status = {};
            if(mode == 'order')
            {   
                status = this.orderStates.find(state => state.id == this.orders.data[order].order_status);
            }
            else
            {
                status = this.billingStates.find(state => state.id == this.orders.data[order].billing_status);
            }
            this.axios
                .post("/orders/changeStatus", {
                    orders: orders,
                    mode: mode,
                    status_id: (mode == 'order') ? this.orders.data[order].order_status : this.orders.data[order].billing_status,
                    print: 0
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Status erfolgreich geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    if(status.mail == 1)
                    {
                        this.sendMail(this.orders.data[order], status);
                    }
                    this.handleEditCell(order, mode + '_status')
                    this.getResults();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        sendMail(order, status){
            this.axios
            .post("/orders/mail/send", {
                'order_id': order.id,
                'company_id': order.company_id,
                'type': 'status',
                'template': status.name,
                'group': status.group
            })
            .then((response) => {
                this.message = response.data.data;
                this.subject = status.subject;
                this.emails = [];
                this.emails.push(order.customer_email);
                this.selected_emails.push(order.customer_email);
                this.company_id = order.company_id;
                this.order = order;
                this.$bvModal.show('mailDocumentModal')
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },
        
        setStatus(mode, status){
            this.$swal({
                    title: "Möchtest du wirklich den Status für alle selektierten Bestellungen ändern?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, nun mach!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/orders/changeStatus", {
                                orders: this.selectedOrders,
                                mode: mode,
                                status_id: status,
                                print: 0,
                            })
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Status erfolgreich geändert!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.selectedOrders = [];
                                this.getResults();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });

        },

        printPicklist(){
            this.$swal({
                    title: "Möchtest du wirklich für alle selektierten Bestellungen die Pickliste drucken?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, nun mach!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/orders/printPicklist", {
                                orders: this.selectedOrders,
                            })
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Status erfolgreich geändert!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.selectedOrders = [];
                                this.getResults();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },
        
        setStatusAndPrint(mode, status){
            this.$swal({
                    title: "Möchtest du wirklich den Status für alle selektierten Bestellungen ändern und die Pickliste drucken?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, nun mach!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/orders/changeStatus", {
                                orders: this.selectedOrders,
                                mode: mode,
                                status_id: status,
                                print: 1,
                            })
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Status erfolgreich geändert!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.selectedOrders = [];
                                this.getResults();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createRoute() {
            this.axios
                .post("/routes", {
                    route_elements: this.selectedOrders,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Routenplanung erfolgreich erstellt.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.selectedOrders = [];
                    this.$router.push({name: 'routes.index'});
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        setBillingBadge(state){

            if(state == 'Offen')
            {
                return 'badge-light'
            }

            if(state == 'Komplett bezahlt')
            {
                return 'badge-success'
            }

            if(state == '1.Mahnung')
            {
                return 'badge-info bg-purple'
            }

            if(state == '2.Mahnung')
            {
                return 'badge-warning'
            }
            if(state == 'Inkasso')
            {
                return 'badge-danger'
            }
            if(state == 'Storniert')
            {
                return 'badge-dark'
            }

            return 'badge-primary';
        },

        setOrderBadge(state){

            if(state == 'Offen')
            {
                return 'badge-light'
            }

            if(state == 'In Bearbeitung')
            {
                return 'badge-warning'
            }

            if(state == 'Ausgeliefert -Versand-')
            {
                return 'badge-success'
            }

            if(state == 'Ausgeliefert -Persönlich-')
            {
                return 'badge-success'
            }

            if(state == 'Ausgeliefert -Selbstabholung-')
            {
                return 'badge-success'
            }

            if(state == 'Storniert')
            {
                return 'badge-dark'
            }

            return 'badge-primary';
        },
        
        getPayments(){
            this.$Progress.start();
            this.axios
                .get("/payments")
                .then((response) => {
                    this.payments = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getDispatches(){
            this.$Progress.start();
            this.axios
                .get("/dispatches")
                .then((response) => {
                    this.dispatches = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getCompanies(){
            this.$Progress.start();
            this.axios
                .get("/companies", { 
                    params: {
                        groups: true
                    }
                })
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getOrderStates(){
            this.axios
                .get("/states/order")
                .then((response) => {
                    this.orderStates = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getBillingStates(){
            this.$Progress.start();
            this.axios
                .get("/states/billing")
                .then((response) => {
                    this.billingStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getResults(page) {
            this.$Progress.start();
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/orders", {
                    params: {
                        page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.orders = response.data;
                    this.$store.commit('orders/setPage', page);
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.getResults();
        this.getPayments();
        this.getDispatches();
        this.getCompanies();
        this.getOrderStates();
        this.getBillingStates();
    }
}
</script>

<style>
.ck-editor__editable {
    min-height: 500px;
    max-height: 500px;
}
</style>